<template>
    <div class="not-paid-page system-message">
        <div class="content">
            <div class="icon not-paid">
                <img src="/build/images/warning.svg">
            </div>
            <div class="title">
                {{ !$auth.user().instance || $auth.user().rights.instance == 'new' ? $t('not-paid-first.title') : $t('not-paid.title') }}
            </div>
            <div class="description">
                {{ !$auth.user().instance || $auth.user().rights.instance == 'new' ? $t('not-paid-first.description') : $t('not-paid.description') }}
            </div>
            <div class="action-button">
                <button @click="openModalSubscription">
                    <span class="text">{{ !$auth.user().instance || $auth.user().rights.instance == 'new' ? $t('not-paid-first.button') : $t('not-paid.button')}}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
            }
        },
        methods: {
            openModalSubscription(){
                if(this.$auth.user().rights.instance == 'not_instance'){
                    this.$root.$emit('open_modal', 'required_instance');
                }else{
                    this.$root.$emit('open_modal', 'change_subscription');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .icon {
        margin-top: 112px;
        margin-bottom: 18px;
        &.not-paid{
            img{
                height: 105px;
            }
        }
    }
</style>