<template>
	<div class="system-message">
        <div class="content">
            <div class="icon not-paid">
				<img src="/build/images/not-allowed.svg">
            </div>
            <div class="title">
				{{$t('not-allowed.title')}}
            </div>
            <div class="description">
                {{ $t('not-allowed.description') }}
            </div>
            <div class="action-button">
				<router-link class="btn-tbf blue center" :to="{name: 'dashboard' }">
					<div class="text">{{$t('navbar.dashboard')}}</div>
				</router-link>
            </div>
        </div>
    </div>
</template>

<script>

	export default {
		data(){
			return{}
		},
		components: {
		},
		methods: {
		}
	}
</script>